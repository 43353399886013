<script>
import axios from 'axios';

export default {
  name: "NouvelleArticles",
  data() {
    return {
      articles: []
    };
  },
  computed: {
    articleRows() {
      const rows = [];
      for (let i = 0; i < this.articles.length; i += 3) {
        rows.push(this.articles.slice(i, i + 3));
      }
      return rows;
    }
  },
  methods: {
    fetchArticles() {
      axios.post('https://api.meritemaritime-fnmm.com/article/list')
        .then(response => {
          this.articles = response.data;
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = `${date.getDate()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} à ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
      return formattedDate;
    }
  },
  created() {
    this.fetchArticles();
  }
}
</script>
<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Nouvelles de la Fédération</h1>
    </div>
  </div>
  <section class="flex w-full flex-col items-center justify-center p-6 mb-24">
  <a :href="`nouvelles-de-la-federation/article/${articles[0]?.id_aritcle_familly}`" class="widget flex flex-col max-w-screen-lg gap-2">
    <div class="title flex items-center gap-2">
      <div class="p-2 bg-[#072653] rounded">
        <img src="@/assets/img/logo.png" class="flex w-12 rounded-full object-cover min-w-12  ">
      </div>
      <h1 class="flex text-4xl">{{ articles[0]?.title }}</h1>
    </div>
    <small>{{ formatDate(articles[0]?.date) }}</small>
    
    <img :src="articles[0]?.photo" class="flex w-full object-cover rounded">
    <p>{{ halfDescription }}</p>
  </a>
  <hr class="flex w-full border my-6 max-w-screen-lg">

    <div v-for="(row, index) in articleRows" :key="index">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div v-for="(article, idx) in row" :key="idx" class="widget flex w-full flex-col gap-2">
          <a :href="`nouvelles-de-la-federation/article/${article.id_aritcle_familly}`">
            <img :src="article.photo" class="art flex w-full rounded">
            <h1 class="text-2xl titleArt">{{ article.title }}</h1>
            <small>{{ formatDate(article.date) }}</small>
          </a>
        </div>
      </div>
      <hr class="flex w-full border my-6 max-w-screen-lg">
    </div>


  </section>
</template>

<style scoped>
@media (max-width: 800px) {
  .widget .title div{
    display: none;
  }

  .widget h1{
    font-size: 30px;
  }

  .containt{
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}

.art{
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

.titleArt{
  max-width: 320px;
}
</style>