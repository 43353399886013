<script>
  import axios from 'axios';

  export default {
    name: 'MegaDash',
    data() {
      return {
        pdfs: []  // Contient les PDF récupérés depuis l'API
      };
    },
    methods: {
      // Fonction pour charger les fichiers PDF depuis l'API
      loadPdfs() {
        axios.get('https://api.meritemaritime-fnmm.com/backoffice/getpdfall')
          .then(response => {
            this.pdfs = response.data;
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des PDF :', error);
          });
      },
      // Fonction pour uploader des fichiers PDF pour les admins
      handleAdminFileUpload(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
          const formData = new FormData();
          formData.append('pdf', file);

          axios.post('https://api.meritemaritime-fnmm.com/backoffice/pdfadmin', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            console.log('Admin PDF uploaded successfully:', response.data);
            this.loadPdfs(); // Recharge les PDF après upload
          }).catch(error => {
            console.error('Error uploading Admin PDF:', error);
          });
        } else {
          alert('Please select a PDF file.');
        }
      },
      // Fonction pour uploader des fichiers PDF pour les membres
      handleMemberFileUpload(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
          const formData = new FormData();
          formData.append('pdf', file);

          axios.post('https://api.meritemaritime-fnmm.com/backoffice/pdfuser', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            console.log('Member PDF uploaded successfully:', response.data);
            this.loadPdfs(); // Recharge les PDF après upload
          }).catch(error => {
            console.error('Error uploading Member PDF:', error);
          });
        } else {
          alert('Please select a PDF file.');
        }
      },
      // Fonction pour supprimer un PDF pour les membres
      deletePdf(pdfId) {
        console.log("hello")
        axios.delete(`https://api.meritemaritime-fnmm.com/backoffice/pdfdeletesectionuser`, {
          data: { id: pdfId }
        }).then(response => {
          console.log('PDF deleted successfully:', response.data);
          this.loadPdfs(); // Recharge les PDF après suppression
        }).catch(error => {
          console.error('Error deleting PDF:', error);
        });
      }
    },
    mounted() {
      this.loadPdfs(); // Charge les PDF lors du montage du composant
    }
  }
</script>

<template>
  <section class="flex w-full h-full">
    <div class="admin flex w-full h-full flex-col p-6 gap-4">
      <h1 class="text-2xl">PDF Admins</h1>
      <label for="uploadAdmin" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded cursor-pointer">
        Ajouter un PDF
      </label>
      <input type="file" name="uploadAdmin" id="uploadAdmin" class="hidden" @change="handleAdminFileUpload">
      <hr class="flex w-full border">
      <div class="listAdmin flex flex-col w-full h-full overflow-y-auto overflow-x-hidden gap-2">
        <div v-for="pdf in pdfs.filter(p => p.role === 'admin')" :key="pdf.url" class="flex bg-neutral-300 px-6 py-2 rounded justify-between">
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text">
              <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
              <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
              <path d="M10 9H8"></path>
              <path d="M16 13H8"></path>
              <path d="M16 17H8"></path>
            </svg>
            <span>{{ pdf.name }}</span>
          </div>
          <div class="flex gap-2">
            <a :href="pdf.url" target="_blank" class="hover:bg-green-400 hover:text-white p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" x2="12" y1="15" y2="3"></line>
              </svg>
            </a>
            <button @click="deletePdf(pdf.id)" class="hover:bg-[#F87170] hover:text-white p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
                <path d="M3 6h18"></path>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                <line x1="10" x2="10" y1="11" y2="17"></line>
                <line x1="14" x2="14" y1="11" y2="17"></line>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="member flex w-full h-full flex-col p-6 gap-4">
      <h1 class="text-2xl">PDF Membres</h1>
      <label for="uploadMembre" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded cursor-pointer">
        Ajouter un PDF
      </label>
      <input type="file" name="uploadMembre" id="uploadMembre" class="hidden" @change="handleMemberFileUpload">
      <hr class="flex w-full border">
      <div class="listMember flex flex-col w-full h-full overflow-y-auto overflow-x-hidden gap-2">
        <div v-for="pdf in pdfs.filter(p => p.role === 'user')" :key="pdf.url" class="flex bg-neutral-300 px-6 py-2 rounded justify-between">
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text">
              <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
              <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
              <path d="M10 9H8"></path>
              <path d="M16 13H8"></path>
              <path d="M16 17H8"></path>
            </svg>
            <span>{{ pdf.name }}</span>
          </div>
          <div class="flex gap-2">
            <a :href="pdf.url" target="_blank" class="hover:bg-green-400 hover:text-white p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" x2="12" y1="15" y2="3"></line>
              </svg>
            </a>
            <button @click="deletePdf(pdf.id)" class="hover:bg-[#F87170] hover:text-white p-2 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
                <path d="M3 6h18"></path>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                <line x1="10" x2="10" y1="11" y2="17"></line>
                <line x1="14" x2="14" y1="11" y2="17"></line>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>