<script>
import AppHeader from "@/components/AppHeader.vue";
import AppMobile from "@/components/AppMobile.vue";
import AppFooter from "@/components/AppFooter.vue";
import CongresImg from "@/components/Congres/CongresImg.vue";
  export default {
    name: 'CongresVue',
    components:{
      AppHeader,
      AppMobile,
      AppFooter,
      CongresImg
    }
  }
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center overflow-x-hidden">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <CongresImg></CongresImg>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>