<script>
export default {
  name: 'AccueilDash',
  mounted() {
    // Récupérer le token depuis le localStorage
    const token = localStorage.getItem('token');

    // Liste des tokens à vérifier
    const specialToken = '61d46ebd6dd7a90cc1adf57898c81ecafuguman';
    const validTokens = ['323fugu46ebd6dd7a90cc1adf57898c81eca', '61d2343d6dd7a90cc1adf57898c81eca', 'adminfabrice.violaa'];
    const adminTokens = [
      '61d46ebd6dd7a90cc1adf57898c81eca',
      'qdsdsqbd6dd7a90cc1adf57898c81eca',
      '4114aba37fabdb62303c7ea766ec7bd8',
      '796cf7aec1d8a86a0c8cc5c34c386ae6',
      '644b32e558f8800e4a53a9385f23bc0f',
      '434848b68ff2c0740bb67f18514d487a',
      '423fda34364871792790f4fe325e02aa',
      '5f1d823941fe154c1476722b984c4650',
      '72ebfae1a6858ce41fd63c714d09a8e1',
      '0770e0c9f9ffb4496293b1cd74dec9a7',
      'f0656ce4121fae47653b6b1d55ec9206',
      '45ecea54853a10f3d7e32ec03376c211',
      '7601e3deb936d631878b97daddae74d4',
      '23df8ff46a29fd04daf106f55be906ce',
      '9d7d34caa485ebab5c4d1a57aa65019a',
      'b3265b4db01777b1bedf812c86579525',
      '61d46ebd6dd7a90cc1adf57898c81eca',
      'ed73dd809f318e7cb7d984f8a707fcc6',
      '638c99c36fba53e170215ec5f13c47c6',
      'c97f27d1c7957fb0fff9c9b2f3cf8f56',
      'b077bc12b74145051cae424b9cafcecb',
      '45bc27a2e1616224c5ad945dadd6ffe6',
      'c4122cb945d7cfb32aae54345e54fc58',
      '2696adb2d5b6f5cc1b7b4fd650cc9544',
      '456a0aa25b07ea81f45b89f6eae990a3'
    ];


    // Redirection en fonction du token
    if (token === specialToken) {
      this.$router.push('/espace-membre/membre');
    } else if (validTokens.includes(token)) {
      this.$router.push('/espace-membre/panel');
    } else if (adminTokens.includes(token)) {
      this.$router.push('/espace-membre/admin');
    } else {
      this.$router.push('/');
    }
  }
}
</script>

<template>
  <section class="flex size-full justify-center items-center">
    <small class="flex items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hammer"><path d="m15 12-8.373 8.373a1 1 0 1 1-3-3L12 9"/><path d="m18 15 4-4"/><path d="m21.5 11.5-1.914-1.914A2 2 0 0 1 19 8.172V7l-2.26-2.26a6 6 0 0 0-4.202-1.756L9 2.96l.92.82A6.18 6.18 0 0 1 12 8.4V10l2 2h1.172a2 2 0 0 1 1.414.586L18.5 14.5"/></svg>En cours de développement.. </small>
  </section>
</template>

<style scoped>

</style>