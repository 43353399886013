<script>
import AppMobile from "@/components/AppMobile.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import Search2 from "@/components/Decore/Search2.vue";

export default {
  name: "Decore2",
  components:{
    Search2,
    AppFooter,
    AppHeader,
    AppMobile,
  }
}
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <Search2></Search2>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>