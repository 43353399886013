<template>
  <div class="flex w-full h-full flex-col p-6 overflow-y-auto overflow-x-hidden">
    <div class="box w-full flex-col gap-4 flex">
      <input v-model="article.h1" type="text" name="h1" id="h1" placeholder="Titre principal" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      
      <!-- Text input for image URL -->
      <input v-model="article.img" type="text" name="img" id="img" placeholder="URL de l'image" class="flex w-full px-6 py-4 bg-neutral-100 rounded" @input="updateImagePreview">
      
      <!-- Image preview -->
      <img v-if="previewImage" :src="previewImage" alt="Image preview" class="w-full max-w-xs">
      
      <textarea v-model="article.text" name="text" id="text" placeholder="Sujet" class="bg-neutral-100 flex w-full p-6"></textarea>

      <!-- File input for PDF upload -->
      <input ref="pdfFile" type="file" name="pdf" id="pdf" class="mt-4" accept=".pdf" @change="handlePdfUpload">
    </div>

    <button @click="saveChanges" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Publier</button>
    <button @click="cancel" class="flex w-full justify-center items-center bg-neutral-400 px-6 py-4 text-white hover:bg-neutral-300 rounded mt-4">Annuler</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VueArticleCreator',
  data() {
    return {
      article: {
        h1: '',
        img: '',
        text: ''
      },
      previewImage: null,
      pdfFile: null  // Store the uploaded PDF file
    }
  },
  methods: {
    saveChanges() {
      const formData = new FormData();

      // Append form data (title, image URL, text, and PDF)
      formData.append('h1', this.article.h1);
      formData.append('img', this.article.img);
      formData.append('text', this.article.text);

      if (this.pdfFile) {
        formData.append('pdf', this.pdfFile);
      }

      axios.post('https://api.meritemaritime-fnmm.com/backoffice/createarticle', formData, {
        // axios.post('http://localhost:3001/backoffice/createarticle', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'  // Ensure multipart/form-data for file upload
        }
      })
      .then(response => {
        console.log('Article créé avec succès:', response.data);
        window.location.reload(); // Reload the page or handle success
      })
      .catch(error => {
        console.error('Erreur lors de la création de l\'article:', error);
      });
    },
    updateImagePreview() {
      // Update the image preview when URL changes
      this.previewImage = this.article.img ? this.article.img : null;
    },
    handlePdfUpload(event) {
      // Store the selected PDF file
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        this.pdfFile = file;
      } else {
        alert('Veuillez sélectionner un fichier PDF valide.');
        this.pdfFile = null;
      }
    },
    cancel() {
      // Reset form fields
      this.article = {
        h1: '',
        img: '',
        text: ''
      };
      this.previewImage = null;
      this.pdfFile = null;
      this.$refs.pdfFile.value = null; // Reset the file input field
    }
  }
}
</script>
  <style scoped>
  textarea {
    min-height: 300px;
  }
  </style>
  