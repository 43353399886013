
<script>
export default {
  name: "AppMobile",
  methods: {
    toggleSidebar() {
      const sidebar = document.querySelector('.sidebar-nav');
      if (sidebar.style.left === '0px') {
        sidebar.style.left = '-390px';
      } else {
        sidebar.style.left = '0px';
      }
    },
    hasTokenInLocalStorage() {
      return localStorage.getItem('token') !== null;
    }
  }
}
</script>
<template>
  <nav class="hidden bg-white w-screen px-6 py-2 fixed border-b shadow z-20 justify-between items-center left-0">
    <div class="flex gap-2">
      <a href="/">
        <img src="@/assets/img/logo.png" class="w-12 h-12">
      </a>
      <img src="@/assets/img/gouv.png" class="w-20">
    </div>
    <div class="cursor-pointer menu " @click="toggleSidebar">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg>
    </div>
  </nav>
  <div class="sidebar-nav flex h-dvh fixed w-full pt-16 z-10">
    <div class="links flex w-full p-6 flex-col bg-white shadow gap-4 overflow-y-auto overflow-x-hidden">
      <a href="/" class="h-12 items-center flex"><h1>Accueil</h1></a>
      <hr class="flex w-full border">
      <h1 class="items-center flex">Présentation</h1>
      <a href="/histoire-maritime" class="h-12 items-center flex">Histoire Mérite Maritime</a>
      <a href="/conseil-de-l-ordre" class="h-12 items-center flex">Le Conseil de l'Ordre</a>
      <a href="/medaille-honneur" class="h-12 items-center flex">La médaille d'honneur</a>
      <a href="/statuts-federation" class="h-12 items-center flex">Statut Fédération</a>
      <hr class="flex w-full border">
      <h1 class=" items-center flex">Actualité FNMM</h1>
      <a href="/nouvelles-de-la-federation" class="h-12 items-center flex">Nouvelles de la Fédération</a>
      <a href="/centre-de-documentation-maritime" class="h-12 items-center flex">Centre de Documentation Maritime</a>
      <a href="/prix-litteraires" class="h-12 items-center flex">Prix Littéraires FNMM</a>
      <hr class="flex w-full border">
      <a href="/congres-fnmm" class="h-12 items-center flex"><h1>Congrès FNMM</h1></a>
      <a href="/sections" class="h-12 items-center flex"><h1>Sections</h1></a>
      <a href="/les-decores" class="h-12 items-center flex"><h1>Les décorés MM-MH</h1></a>
      <a href="/contact" class="h-12 items-center flex"><h1>Contact</h1></a>
      <hr class="flex w-full border">

      <a v-if="!hasTokenInLocalStorage()" href="/espace-membre" class="p-4 bg-[#3E5C8E] text-white items-center rounded shadow">
        Se connecter
      </a>
      <a v-else href="/espace-membre/panel" class="p-4 bg-[#3E5C8E] text-white items-center rounded shadow">
        Profil
      </a>






    </div>
    <div class="flex justify-center p-4" @click="toggleSidebar">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
    </div>
  </div>
</template>

<style scoped>
.sidebar-nav{
  left: -390px;
  max-width: 390px;
  transition: left 0.3s ease-in-out;
}

@media (max-width: 1024px) {
  nav{
    display: flex;
  }
}
</style>