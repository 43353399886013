<script>
  import AppMobile from "@/components/AppMobile.vue";
  import AppHeader from "@/components/AppHeader.vue";
  import AppFooter from "@/components/AppFooter.vue";
  import SectionVueTexte from "@/components/Section/Vue/SectionVueTexte.vue";

  export default {
    name: 'SectionVue',
    components: {
      SectionVueTexte,
      AppFooter,
      AppHeader,
      AppMobile,
    },
  }
</script>

<template>
  <div class="screen flex flex-col size-full items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <SectionVueTexte></SectionVueTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>