<script>
  import AppMobile from "@/components/AppMobile.vue";
  import AppHeader from "@/components/AppHeader.vue";
  import AppFooter from "@/components/AppFooter.vue";
  import PrixVue from "@/components/Prix/PrixVue.vue";

  export default {
    name:'Prix',
    components:{
      PrixVue,
      AppFooter,
      AppHeader,
      AppMobile

    }
  }
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <PrixVue></PrixVue>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>