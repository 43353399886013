
<script>
import axios from 'axios';

export default {
  name: 'CongresTexte',
  data() {
    return {
      congres: []
    };
  },
  mounted() {
    axios.post('https://api.meritemaritime-fnmm.com/congre/list')
      .then(response => {
        this.congres = response.data;
      })
      .catch(error => {
        console.error('There was an error fetching the congres data:', error);
      });
  }
};
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Congrès FNMM</h1>
    </div>
  </div>
  <section class="flex w-full h-dvh items-center flex-col p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg mb-24 flex-col">
        <div v-for="congre in congres" :key="congre.id" class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">{{ congre.nom }}</h1>
          <hr class="border w-full flex">
          <a :href="`/congres-fnmm/${congre.id}`" class="btn bg-[#072653] text-white px-6 py-2 rounded">voir</a>          
        </div>
      </div>
    </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>

