<template>
  <div class="flex w-full h-full flex-col p-6 overflow-y-auto overflow-x-hidden">
    <div class="box w-full flex-col gap-4 flex">
      <input 
        type="text" 
        name="title" 
        id="title" 
        placeholder="Titre principal" 
        class="flex w-full px-6 py-4 bg-neutral-100 rounded" 
        v-model="article.title"
      >
      
      <!-- Image URL input with preview -->
      <div>
        <input 
          type="text" 
          name="img" 
          id="img" 
          placeholder="URL de l'image" 
          class="flex w-full px-6 py-4 bg-neutral-100 rounded mb-4"
          v-model="article.img"
        >
        <div v-if="article.img" class="w-full max-w-xs mx-auto">
          <img :src="article.img" alt="Image preview" class="w-full h-auto rounded">
        </div>
      </div>

      <!-- PDF File input with preview -->
      <div>
        <input 
          type="file" 
          name="pdf" 
          id="pdf" 
          class="flex w-full px-6 py-4 bg-neutral-100 rounded mb-4"
          @change="onFileChange"
        >
        <div v-if="article.pdf">
          <a :href="article.pdf" target="_blank" class="text-blue-600 underline">Voir le PDF</a>
        </div>
      </div>

      <hr class="flex border w-full my-4">

      <textarea 
        name="description" 
        id="description" 
        placeholder="Sujet" 
        class="bg-neutral-100 flex w-full p-6" 
        v-model="article.description"
      ></textarea>
    </div>
    <button @click="saveChanges" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Publier</button>
    <button @click="cancel" class="flex w-full justify-center items-center bg-neutral-400 px-6 py-4 text-white hover:bg-neutral-300 rounded mt-4">Annuler</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VueArticle',
  data() {
    return {
      article: {
        title: '',
        description: '',
        img: '',
        pdf: '', // Stocker le lien du PDF
        pdfFile: null // Stocker le fichier PDF sélectionné
      }
    };
  },
  mounted() {
    this.retrieveEditedArticle();
  },
  watch: {
    'article.title': function(newTitle) {
      this.updateLocalStorage();
    },
    'article.description': function(newDescription) {
      this.updateLocalStorage();
    }
  },
  methods: {
    retrieveEditedArticle() {
      const editedArticle = localStorage.getItem('editedArticle');
      if (editedArticle) {
        try {
          const parsedArticle = JSON.parse(editedArticle);
          if (parsedArticle && typeof parsedArticle === 'object') {
            // Remplir les champs avec les données récupérées
            this.article.title = parsedArticle.title || '';
            this.article.description = parsedArticle.description || '';
            this.article.img = parsedArticle.photo || ''; // Assurez-vous que la clé "photo" est utilisée pour l'image
            this.article.pdf = parsedArticle.pdf || ''; // Utiliser la clé "pdf" pour le lien du PDF
          }
        } catch (error) {
          console.error('Error parsing editedArticle from local storage:', error);
        }
      }
    },
    updateLocalStorage() {
      localStorage.setItem('editedArticle', JSON.stringify(this.article));
    },
    onFileChange(event) {
      this.article.pdfFile = event.target.files[0]; // Assigner le fichier PDF à article.pdfFile
    },
    async saveChanges() {
      try {
        const editedArticleId = localStorage.getItem('editedArticleId');
        const id_article = localStorage.getItem('id_article');
        const formData = new FormData();
        
        formData.append('title', this.article.title);
        formData.append('description', this.article.description);
        formData.append('img', this.article.img);
        
        if (this.article.pdfFile) {
          formData.append('pdf', this.article.pdfFile); // Ajouter le fichier PDF s'il est sélectionné
        }
        
        const response = await axios.post(`https://api.meritemaritime-fnmm.com/backoffice/article/edit/${id_article}`, formData, {
          // const response = await axios.post(`http://localhost:3001/backoffice/article/edit/${id_article}`, formData, {

          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log('Article updated successfully:', response.data);
        this.updateLocalStorage();
      } catch (error) {
        console.error('Error updating article:', error);
      }
    },
    refreshComponent() {
      this.retrieveEditedArticle();
    }
  }
};
</script>

<style scoped>
textarea {
  min-height: 300px;
}
</style>
