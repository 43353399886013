<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import StatutTexte from "@/components/Statut/StatutTexte.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Statut",
  components: {
    AppMobile,
    AppHeader,
    AppFooter,
    StatutTexte
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full gap-8 items-center">
    <AppHeader></AppHeader>
    <AppMobile></AppMobile>
    <StatutTexte></StatutTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>