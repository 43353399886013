<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">PRIX LITTERAIRES</h1>
    </div>
  </div>
  <hr class="w-full flex border max-w-screen-lg">

  <div v-if="prixList.length" v-for="category in prixList" :key="category.id" class="flex flex-col w-full items-center px-6 justify-center gap-8">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">{{ category.name }}</h1>
    </div>

    <section v-if="category.items && category.items.length" class="flex w-full items-center justify-center p-6 flex-col gap-4">
      <div class="flex w-full items-center justify-center">
        <div class="containt flex w-full gap-8 max-w-screen-lg">
          <div v-for="item in category.items" :key="item.id_prix" class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
            <h1 class="text-2xl">{{ item.nom.trim() }}</h1>
            <hr class="border w-full flex">
            <img :src="item.img" class="border-2">
          </div>
        </div>
      </div>
    </section>
    <hr class="w-full flex border max-w-screen-lg">
  </div>

</template>


<script>
import axios from 'axios';

export default {
  name: 'PrixVue',
  data() {
    return {
      prixList: []
    };
  },
  mounted() {
    this.fetchPrixList();
  },
  methods: {
    async fetchPrixList() {
      try {
        const response = await axios.get('https://api.meritemaritime-fnmm.com/prix/list');
        this.prixList = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des prix:', error);
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 1024px) {
  .titre {
    margin-top: 100px;
  }
}
@media (max-width: 800px) {
  .containt {
    flex-direction: column;
  }
}
</style>
