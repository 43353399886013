<script>
import axios from 'axios';

export default {
  name: "SectionVueText",
  data() {
    return {
      sectionData: null,
      actualitesectionData: [] // Initialize as an empty array

    };
  },
  mounted() {
    this.fetchSectionData();
    this.fetchActualiteSectionData();

  },
  methods: {
    fetchSectionData() {
      const id = this.getSectionIdFromURL();
      axios.get(`https://api.meritemaritime-fnmm.com/section/${id}`)
        .then(response => {
          this.sectionData = response.data;
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des données de l'article:", error);
        });
    },
    fetchActualiteSectionData() {
  const id = this.getSectionIdFromURL();
  axios.get(`https://api.meritemaritime-fnmm.com/backoffice/actualitesection`, {
    params: {
      id_section_familly: id
    }
  })
  .then(response => {
    if (Array.isArray(response.data) && response.data.length > 0) {
      this.actualitesectionData = response.data;
    } else {
      this.actualitesectionData = []; // Default to an empty array if no data
    }
  })
  .catch(error => {
    console.error("Erreur lors de la récupération des données de la section:", error);
    this.actualitesectionData = []; // Default to an empty array in case of error
  });
},
  getSectionIdFromURL() {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    return id;
  },
  getFirstLink() {
    if (this.sectionData && this.sectionData.length > 0) {
      return this.sectionData[0].link;
    }
    return null; // Retourne null si sectionData n'est pas encore disponible
  }
},
  computed: {
    filteredSectionData() {
      if (!this.sectionData || this.sectionData.length <= 1) {
        return [];
      }
      return this.sectionData.slice(1);
    }
  }
};
</script>



<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 v-if="sectionData && sectionData.length > 0" class="text-4xl border-b-4 pb-2 border-[#072653]">{{
        sectionData[0].title }}</h1>
    </div>
  </div>

  <section class="flex flex-col justify-center p-6">
    <p v-if="sectionData && sectionData.length > 0" class="max-w-screen-lg flex mb-12">{{ sectionData[0].description }}
    </p>

    <div class="flex flex-col items-center px-6 py-12">
  <div class="w-full max-w-screen-lg">
    <h1 class="text-4xl relative inline-block pb-2">
      Actualités
      <!-- Border line -->
      <span class="absolute bottom-0 left-0 w-full h-1 bg-[#072653]"></span>
    </h1>
    <div v-if="actualitesectionData.length > 0" class="space-y-8">
      <div v-for="item in actualitesectionData" :key="item.id" class="text-center">
        <img :src="item.img" alt="Actualité image" class="mx-auto mb-4 max-w-full h-auto">
        <p class="mb-4">{{ item.text }}</p>
        <!-- PDF download button -->
        <div v-if="item.pdf" class="text-center">
          <a :href="item.pdf" download class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Télécharger le PDF</a>
        </div>
      </div>
    </div>
    <p v-else class="text-center mt-8">Aucune actualité disponible.</p>
  </div>
</div>


    <hr class="border w-full max-w-screen-lg flex my-8 mb-24">

    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <img :src="sectionData && sectionData[0].img ? sectionData[0].img : require('@/assets/img/user/users.jpg')" class="flex rounded-full w-36 border  object-cover h-36 border-black">
          <h1 class="text-2xl">{{ sectionData ? sectionData[0].role : 'Loading...' }}</h1>
          <hr class="border w-full flex">
          <h1 class="text-2xl">{{ sectionData ? sectionData[0].nom + ' ' + sectionData[0].prenom : 'Loading...' }}</h1>
        </div>
      </div>
    </section>

    <section class="flex w-full items-center justify-center p-6">
  <div class="containt flex flex-wrap w-full gap-8 max-w-screen-lg mb-24">
    <div class="grid grid-cols-4 gap-8 w-full">
      <div v-for="(item, index) in filteredSectionData" :key="index"
        class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <img :src="item.img ? item.img : require('@/assets/img/user/users.jpg')" 
             class="flex rounded-full w-36 border h-36 object-cover border-black">
        <h1 class="text-2xl text-center">{{ item.role }}</h1>
        <hr class="border w-full flex">
        <h1 class="text-2xl text-center">{{ item.nom }} {{ item.prenom }}</h1>
      </div>
    </div>
  </div>
</section>

    <div class="flex w-full flex-col items-center justify-center mt-24">
      <a v-if="getFirstLink()" :href="getFirstLink()" class="bg-[#3E5C8E] rounded text-white p-4 px-20 text-xl">Consulter le site</a>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre {
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .containt {
    flex-direction: column;
  }
  
  .grid {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr; 
  }
}
</style>
