<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import OrdreTexte from "@/components/Ordre/OrdreTexte.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Ordre",
  components : {
    AppMobile,
    AppFooter,
    OrdreTexte,
    AppHeader,
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full gap-8 items-center">
    <AppHeader></AppHeader>
    <AppMobile></AppMobile>
    <OrdreTexte></OrdreTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>