<template>
  <section class="flex w-full h-full">
    <div class="sidebar overflow-y-auto overflow-x-hidden flex-col flex gap-2 w-full h-full border-r p-6">
      <div class="search">
        <input type="text" name="search" id="search" placeholder="Rechercher une section" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Créer une section</button>
        <hr class="w-full flex border my-4">
      </div>

      <div v-for="section in sections" :key="section.id" class="article flex px-6 py-4 bg-neutral-100 flex-col gap-4 rounded">
        <h1>{{ section.nom }}</h1>
        <div class="btn flex w-full items-center gap-4">
          <button @click="fetchSectionDetails(section.id_section_familly)" class="bg-neutral-200 p-2 flex w-full justify-center rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil">
              <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path>
              <path d="m15 5 4 4"></path>
            </svg>
          </button>
          <button class="bg-red-400 flex w-full text-white p-2 rounded justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
              <path d="M3 6h18"></path>
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
              <line x1="10" x2="10" y1="11" y2="17"></line>
              <line x1="14" x2="14" y1="11" y2="17"></line>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="info flex w-full flex-col gap-2 p-6 border-r">
      <div class="search">
        <input type="text" v-model="title" placeholder="Titre de la section" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <textarea v-model="description" placeholder="Description de la section" class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4"></textarea>
        <input type="text" v-model="link" placeholder="Lien du site" class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4">
        
        <button
          @click="saveSectionDetails"
          class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4"
        >
          Enregistrer
        </button>
        <hr class="w-full flex border my-4">
      </div>
      <h1>Membres de la section :</h1>
      <section class="flex w-full infoSection flex-col gap-2 overflow-y-auto overflow-x-hidden">
        <div
          v-for="(detail, index) in formattedSectionDetails"
          :key="index"
          class="article flex px-6 py-4 bg-neutral-100 w-full items-center gap-4 rounded"
        >
          <h1>{{ detail.fullName }} - {{ detail.role }}</h1>
          <div class="flex items-center gap-4">
            <div class="btn flex w-full items-center gap-4">
              <button @click="editUser(detail)" class="bg-neutral-200 p-2 flex w-full justify-center rounded">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil">
                  <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path>
                  <path d="m15 5 4 4"></path>
                </svg>
              </button>
              <button @click="deleteUser(detail)" class="bg-red-400 flex w-full text-white p-2 rounded justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
                  <path d="M3 6h18"></path>
                  <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                  <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                  <line x1="10" x2="10" y1="11" y2="17"></line>
                  <line x1="14" x2="14" y1="11" y2="17"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="user-form flex flex-col gap-2 w-full h-full p-6">
      <h1>Détails de l'utilisateur sélectionné :</h1>
      <input
        type="text"
        v-model="selectedUser.prenom"
        placeholder="Prénom"
        class="flex w-full px-6 py-4 bg-neutral-100 rounded"
      >
      <input
        type="text"
        v-model="selectedUser.nom"
        placeholder="Nom"
        class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4"
      >
      <input
        type="text"
        v-model="selectedUser.role"
        placeholder="Rôle"
        class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4"
      >
      <input
        type="text"
        v-model="selectedUser.img"
        placeholder="URL de l'image"
        class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4"
      >
      <img v-if="selectedUser.img" :src="selectedUser.img" alt="User Image" class="w-32 h-32 mt-4">
      <button @click="saveUserChanges" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">
        Enregistrer les modifications
      </button>
      <button @click="createUser" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">
        Crée l'utilisateur
      </button>
    </div>
  </section>
</template>
<script>
import axios from 'axios';

export default {
  name: 'SectionsDash',
  data() {
    return {
      sections: [],
      sectionDetails: [],
      title: '',
      description: '',
      link: '',
      selectedUser: {
        prenom: '',
        nom: '',
        img: '',  // Changement de `null` à une chaîne vide
        role: '',
        id_section: ''
      },
      selectedSectionId: null,
    };
  },
  computed: {
    formattedSectionDetails() {
      return this.sectionDetails.map(detail => {
        return {
          fullName: `${detail.prenom.trim()} ${detail.nom.trim()}`,
          role: detail.role,
          img: detail.img,
          prenom: detail.prenom.trim(),
          nom: detail.nom.trim(),
          id_section: detail.id_section
        };
      });
    }
  },
  methods: {
    // Modification pour gérer l'URL de l'image en tant que chaîne de texte
    onImageChange(event) {
      this.selectedUser.img = event.target.value; // Changer l'URL de l'image
    },
    async saveUserChanges() {
      try {
        const userData = {
          prenom: this.selectedUser.prenom,
          nom: this.selectedUser.nom,
          role: this.selectedUser.role,
          id_section: this.selectedUser.id_section,
          img: this.selectedUser.img || ''
        };

        const response = await axios.post('https://api.meritemaritime-fnmm.com/editmembersection', userData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('User updated successfully:', response.data);
        } else {
          console.error('Error updating user:', response.data);
        }
      } catch (error) {
        console.error('Error saving user changes:', error);
      }
    },
    async createUser() {
      try {
        const newUser = {
          prenom: this.selectedUser.prenom,
          nom: this.selectedUser.nom,
          role: this.selectedUser.role,
          img: this.selectedUser.img || '',
          id_section: this.selectedSectionId
        };

        const response = await axios.post('https://api.meritemaritime-fnmm.com/addmembersection', newUser, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('User created successfully:', response.data);
        } else {
          console.error('Error creating user:', response.data);
        }
      } catch (error) {
        console.error('Error creating user:', error);
      }
    },
    async fetchSections() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/section/list');
        this.sections = response.data;
        localStorage.setItem('sections', JSON.stringify(this.sections));
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    },
    async fetchSectionDetails(id_section_familly) {
      try {
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/section/${id_section_familly}`);
        this.sectionDetails = response.data;
        localStorage.setItem('sectionDetails', JSON.stringify(this.sectionDetails));

        if (this.sectionDetails.length > 0) {
          const details = this.sectionDetails[0];
          this.title = details.title || '';
          this.description = details.description || '';
          this.link = details.link || '';
          this.selectedSectionId = details.id_section || null;
        }
      } catch (error) {
        console.error('Error fetching section details:', error);
      }
    },
    editUser(detail) {
      this.selectedUser.prenom = detail.prenom;
      this.selectedUser.nom = detail.nom;
      this.selectedUser.img = detail.img;
      this.selectedUser.role = detail.role;
      this.selectedUser.id_section = detail.id_section;

      localStorage.setItem('selectedUser', JSON.stringify(this.selectedUser));
    },
    async deleteUser(detail) {
      try {
        const response = await axios.delete('https://api.meritemaritime-fnmm.com/deletemembersection', {
          data: { id_section: detail.id_section }
        });

        if (response.status === 200) {
          console.log('User successfully deleted:', response.data);
          this.sectionDetails = this.sectionDetails.filter(item => item.id_section !== detail.id_section);
          localStorage.setItem('sectionDetails', JSON.stringify(this.sectionDetails));
        } else {
          console.error('Error deleting user:', response.data);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    async saveSectionDetails() {
      try {
        const updatedDetails = {
          title: this.title,
          description: this.description,
          link: this.link,
          id_section: this.selectedSectionId
        };

        const response = await axios.post('https://api.meritemaritime-fnmm.com/editnamesection', updatedDetails);

        if (response.status === 200) {
          console.log('Section updated successfully:', response.data);
          localStorage.setItem('sectionDetails', JSON.stringify([updatedDetails]));
        } else {
          console.error('Error updating section:', response.data);
        }
      } catch (error) {
        console.error('Error updating section details:', error);
      }
    },
  },
  mounted() {
    this.fetchSections();

    const storedSectionDetails = localStorage.getItem('sectionDetails');
    if (storedSectionDetails) {
      this.sectionDetails = JSON.parse(storedSectionDetails);
      if (this.sectionDetails.length > 0) {
        const details = this.sectionDetails[0];
        this.title = details.title || '';
        this.description = details.description || '';
        this.link = details.link || '';
      }
    }

    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      this.selectedUser = JSON.parse(storedUser);
    }
  }
};
</script>
<style scoped>
.sidebar{
  max-width: 300px;
}

.infoSection .btn{
  max-width: 50px;
}
</style>
