<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <router-view />
</template>
<script>
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Nabla&family=Poppins:wght@300&family=Rubik+Scribble&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: 'Poppins', sans-serif;
}

html{
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Lato', sans-serif;
}

</style>
