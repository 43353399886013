<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import HistoireTexte from "@/components/Histoire/HistoireTexte.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Histoire",
  components : {
    AppMobile,
    AppFooter,
    AppHeader,
    HistoireTexte,
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full gap-8 items-center">
    <AppHeader></AppHeader>
    <AppMobile></AppMobile>
    <HistoireTexte></HistoireTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>