<script>
export default {
  name: "Contact"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Nous contacter</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg mb-24">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Visiteur</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Vous n'êtes pas membre, mais vous souhaitez nous contacter ? C'est ici !</p>
        <a href="/contact" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>

      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Espace Membre</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Déjà membre de notre fédération et besoin de nous contacter ? Nous sommes là pour vous aider !</p>
        <a href="/espace-membre" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right"><path d="M18 8L22 12L18 16"/><path d="M2 12H22"/></svg></a>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}
</style>