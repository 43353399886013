<script>
  import AppHeader from "@/components/AppHeader.vue";
  import AppMobile from "@/components/AppMobile.vue";
  import AppFooter from "@/components/AppFooter.vue";
  import CongresTexte from "@/components/Congres/CongresTexte.vue";

  export default {
    name: 'Congres',
    components: {CongresTexte, AppFooter, AppMobile, AppHeader}
  }
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center overflow-x-hidden">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <CongresTexte></CongresTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>